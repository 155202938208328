import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import WhatsAppButton from "../WhatsappButton"; // Import the WhatsAppButton component

const menuDataOne = [
  { title: "About Us", href: "/about" },
  { title: "Departments", href: "/departments" },
  { title: "Doctors", href: "/doctors" },
  { title: "Timetable", href: "/timetable" },
  { title: "Appointment", href: "/appointments" },
  { title: "Testimonials", href: "/" },
];
const menuDataTwo = [
  { title: "Blog", href: "/blog" },
  { title: "Contact Us", href: "/contact" },
  { title: "FAQs", href: "/" },
  { title: "Privacy Policy", href: "/" },
  { title: "Terms and Conditions", href: "/" },
];

export default function Footer() {
  return (
    <>
      <footer className="cs_footer cs_style_1 cs_heading_color">
        <div
          className="cs_footer_logo_wrap"
          style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
        >
          <div
            className="cs_footer_brand"
            style={{ backgroundImage: "url(/images/footer_logo_bg.svg)" }}
          >
            <img
              src="/images/favicon.webp"
              alt="Logo Icon"
              className="cs_footer_brand_icon"
              style={{ maxWidth: "3vw" }}
            />
            <img
              src="/images/logoText.webp"
              alt="Logo Text"
              className="cs_footer_brand_text"
              style={{
                width: "200px !important",
                maxWidth: "200px !important",
              }}
            />
          </div>
        </div>
        <div className="cs_footer_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="cs_footer_item">
                  <img
                    src="/images/logo.webp"
                    alt="Logo"
                    className="cs_footer_brand_icon_two"
                    style={{ maxWidth: "8vw", paddingBottom: "2rem" }}
                  />
                  <ContactInfoWidget />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="cs_footer_item">
                  <MenuWidget data={menuDataOne} />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="cs_footer_item">
                  <MenuWidget data={menuDataTwo} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="cs_footer_item">
                  <Newsletter
                    title="Be Our Subscribers"
                    subTitle="To get the latest news about health from our experts"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_footer_bottom cs_accent_bg">
          <div className="container">
            <div className="cs_footer_bottom_in">
              <SocialWidget />
              <div className="cs_copyright">
                Copyright © HelalHealthCare. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
      <WhatsAppButton />
    </>
  );
}
