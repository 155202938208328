import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const TopDoctors = ({ sectionTitle }) => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use useNavigate hook

  // Fetch 4 random doctors from Firebase
  const fetchRandomDoctors = async () => {
    const querySnapshot = await getDocs(collection(db, "doctors"));
    const allDoctors = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Select 4 random doctors
    const randomDoctors = allDoctors
      .sort(() => 0.5 - Math.random())
      .slice(0, 4);
    setDoctors(randomDoctors);
    setLoading(false);
  };

  useEffect(() => {
    fetchRandomDoctors();
  }, []);

  const formatDoctorName = (name) => {
    return name.startsWith("Dr.") ? name : `Dr. ${name}`;
  };

  const capitalizeEachWord = (string) => {
    if (!string || typeof string !== "string") return ""; // Safeguard to handle undefined, null, or non-string values
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      {sectionTitle && (
        <>
          <SectionHeading title={sectionTitle} center />
          <Spacing md="60" />
        </>
      )}

      <Grid container spacing={4} justifyContent="center">
        {doctors.map((doctor) => (
          <Grid item xs={12} sm={6} md={3} key={doctor.id}>
            <Link
              to={`/doctor/${doctor.id}`}
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  padding: "1rem",
                  backgroundColor: "#fff",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                  },
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    height: "300px",
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "8px",
                    "&:hover img": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease-in-out",
                    },
                  }}
                >
                  <img
                    src={doctor.image || "/images/doctors/default.png"}
                    alt={doctor.doctorName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </CardMedia>

                <CardContent
                  sx={{
                    textAlign: "left",
                    flexGrow: 1,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      color: "#333",
                    }}
                  >
                    {formatDoctorName(doctor.doctorName)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.95rem",
                      color: "#666",
                      marginTop: "0.5rem",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeEachWord(doctor.degree)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.95rem",
                      color: "#666",
                      marginTop: "0.5rem",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeEachWord(doctor.currentPosition)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.9rem",
                      color: "#888",
                      marginTop: "0.25rem",
                    }}
                  >
                    {capitalizeEachWord(doctor.hospital)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontWeight: "800",
                      fontSize: "0.9rem",
                      color: "#888",
                      marginTop: "0.25rem",
                    }}
                  >
                    {capitalizeEachWord(doctor.department)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.9rem",
                      color: "#888",
                      marginTop: "0.25rem",
                    }}
                  >
                    Experience: {doctor.experience} years
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>

      <Container sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/doctors")} // Using useNavigate to redirect to the doctors' page
        >
          View More Doctors
        </Button>
      </Container>
    </Container>
  );
};

export default TopDoctors;
