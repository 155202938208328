import React from "react";
import HeroStyle6 from "../Hero/HeroStyle6";
// import AboutSection from "../Section/AboutSection";
import BrandsSectionCustom from "../Section/BrandsSection/BrandsSectionCutsom";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
// import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
import WorkingProcess from "../Section/WorkingProcess";
import TopDoctors from "../TopDoctors/TopDoctors";

const faqData = [
  {
    title: "What is medical tourism?",
    content:
      "Medical tourism involves traveling to India for medical care. HelalHealthcare connects foreign customers with leading Indian healthcare providers.",
  },
  {
    title: "Which hospitals do you work with?",
    content:
      "We have partnerships with top hospitals across India, providing world-class medical services. Contact us to learn more about our network of hospitals.",
  },
  {
    title: "How do I book a medical tour?",
    content:
      "You can book a medical tour by filling out our online form or contacting our office. Our team will guide you through the process step by step.",
  },
  {
    title: "Do you offer post-treatment support?",
    content:
      "Yes, we provide follow-up care and support after treatment to ensure a smooth recovery, whether you stay in India or return to your home country.",
  },
  {
    title: "How do I know if my insurance covers treatment in India?",
    content:
      "We work with insurance providers to determine coverage options for treatments in India. Our team will help you understand your insurance benefits.",
  },
];

const workingProcessData = [
  {
    title: "Book Your Medical Tour",
    subTitle:
      "Share your updated medical reports with complain to our office directly or complete our online form. Within 24 hours, you will receive our expert opinion, including an estimated cost for the treatment, the expected duration of your stay in India, and the success rates of the treatment. we kindly request that you provide copies of the patient and attendant’s passports, as these are necessary for us to issue a medical visa invitation letter from the hospital.",
    iconUrl: "https://img.icons8.com/papercut/100/event-accepted.png",
    number: "01",
  },
  {
    title: "Arrival and Accommodation",
    subTitle:
      "After your visa is issued by the embassy, please proceed to book your flight and share your ticket with us. We will arrange for a dedicated staff to greet you at the airport and assist you in settling into a guest house near the hospital, ensuring a comfortable and seamless experience during your stay in India.",
    iconUrl: "https://img.icons8.com/fluency/100/airplane-landing.png",
    number: "02",
  },
  {
    title: "Meet Your Medical Team",
    subTitle:
      "With a prior appointment, our staff will accompany you to the doctor for your final treatment consultation. The doctor will go over your medical history, explain the treatment plan, and answer any questions you have, ensuring you feel comfortable before moving forward. Our team will be there to support you every step of the way.",
    iconUrl:
      "https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/100/external-Medical-Team-medical-concepts-smashingstocks-flat-smashing-stocks.png",
    number: "03",
  },
  {
    title: "Receive Treatment",
    subTitle:
      "We guarantee world-class medical care in India, tailored to your specific healthcare needs. Our skilled professionals provide personalized treatment plans, ensuring your comfort and well-being. Your health is our priority, and we are committed to delivering the highest standards of care throughout your journey.",
    iconUrl:
      "https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/100/external-Medical-Team-medical-concepts-smashingstocks-flat-smashing-stocks-2.png",
    number: "04",
  },
  {
    title: "Post-treatment and Follow-up",
    subTitle:
      "After your treatment, we will provide a clear follow-up plan to monitor your recovery. Our team will give you instructions on post-treatment care, answer your questions, and schedule regular check-ins to track your progress. Your health is our priority, and we’re here to support you throughout your recovery.",
    iconUrl: "https://img.icons8.com/officel/100/treatment-plan.png",
    number: "05",
  },
  {
    title: "Video Consultations",
    subTitle:
      "We provide video consultations for your convenience, letting you connect with our doctors from home. You can discuss your concerns and get expert advice without needing an in-person visit. This flexible option ensures you receive timely support during your treatment.",
    iconUrl: "https://img.icons8.com/dusk/100/video-conference.png",
    number: "06",
  },
  {
    title: "Language Translation Service",
    subTitle:
      "We offer translation services to ensure clear communication for our international patients. Our translators assist during consultations, helping you understand medical information and express your concerns easily. This service makes your experience more accessible.",
    iconUrl: "https://img.icons8.com/color/100/language.png",
    number: "07",
  },
  {
    title: "Free Airport Pickup & Drop for Patients",
    subTitle:
      "We offer complimentary airport pickup and drop-off for patients. Our team will greet you upon arrival and ensure a smooth transfer to your accommodation for a hassle-free experience.",
    iconUrl: "https://img.icons8.com/fluency/100/taxi.png",
    number: "08",
  },
];

const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Indian Healthcare Expertise",
    subTitle:
      "We connect foreign patients with top healthcare providers across India, ensuring world-class services and care.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Personalized Care",
    subTitle:
      "Our team tailors medical treatment plans to meet the individual needs of each patient, providing personalized attention at every step.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Affordable Healthcare",
    subTitle: `We work with Indian healthcare providers to ensure that patients receive affordable medical care without compromising on quality.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Expert Guidance",
    subTitle:
      "We offer professional guidance through every stage of the medical tourism process, from initial consultation to follow-up care.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Trusted Indian Partners",
    subTitle:
      "Our partnerships with leading hospitals in India ensure that you receive the best treatment available.",
  },
];

// const departmentData = [
//   {
//     title: "Orthopedics Department",
//     iconUrl: "images/home_1/orthopedics.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Pediatric Department",
//     iconUrl: "images/home_1/pediatrics.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Gynecology Department",
//     iconUrl: "images/home_1/gynecology.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Cardiology Department",
//     iconUrl: "images/home_1/cardiology.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Neurology Department",
//     iconUrl: "images/home_1/neuron.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Urology Department",
//     iconUrl: "images/home_1/urology.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Dentistry Department",
//     iconUrl: "images/home_1/dentistry.png",
//     href: "/departments/department-details",
//   },
//   {
//     title: "Nephrology Department",
//     iconUrl: "images/home_1/nephrology.png",
//     href: "/departments/department-details",
//   },
// ];

const awardData = [
  {
    title: "Malcolm Baldrige National Quality Award",
    subTitle:
      "This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "HIMSS Davies Award",
    subTitle:
      "This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Healthgrades National’s Best Hospital",
    subTitle:
      "This recognition is given to Indian hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Joint Commission Gold Seal of Approval",
    subTitle:
      "This recognition is given to Indian hospitals that have met rigorous standards for patient safety and quality of care.",
    iconUrl: "images/icons/award.svg",
  },
];

export default function Home() {
  pageTitle("Home - HelalHealthcare");
  return (
    <>
      <HeroStyle6
        title="Commitment to Quality Care"
        subTitle="HelalHealthcare connects foreign patients with world-class Indian healthcare providers for affordable and personalized care."
        bgUrl="https://prohealth-react.vercel.app/images/home_2/hero_bg.jpeg"
        imgUrl="https://prohealth-react.vercel.app/images/home_2/patents.png"
        btnUrl="/contact"
        funfactList={[
          { number: "50+", title: "Indian Healthcare Partners" },
          { number: "98%", title: "Client Satisfaction" },
          { number: "10000+", title: "Successful Treatments" },
          { number: "20+", title: "Countries Served" },
        ]}
      />
      {/* Start Feature Section */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={50}
        bottomLg={50}
        bottomXl={50}
      >
        <FeaturesSection sectionTitle="Why Choose Us?" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start Brand Section */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={50}
        bottomLg={50}
        bottomXl={50}
      >
        <BrandsSectionCustom sectionTitle="Specialized Departments" />
      </Section>

      {/* Start About Section */}
      {/* <Section>
        <AboutSection
          imgUrl="https://prohealth-react.vercel.app/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About HelalHealthcare"
          subTitle="MEDICAL TOURISM EXPERTS"
          featureList={[
            {
              featureListTitle:
                "HelalHealthcare specializes in connecting foreign patients with top Indian healthcare providers.",
              featureListSubTitle:
                "We offer personalized medical tours, ensuring each patient receives the care and attention they deserve at affordable rates in India.",
            },
          ]}
        />
      </Section> */}
      {/* End About Section */}
      {/* Start Departments Section */}
      {/* <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Specialized Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}

      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={50}
        bottomLg={50}
        bottomXl={50}
      >
        <WorkingProcess
          sectionTitle="How It Works"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={50}
        bottomLg={50}
        bottomXl={50}
      >
        <TopDoctors sectionTitle="Top Doctors" />
      </Section>
      {/* Start Award Section */}
      <Section topMd={50} topLg={50} topXl={50}>
        <AwardSection sectionTitle="Recognitions & Awards" data={awardData} />
      </Section>
      {/* End Award Section */}

      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="What Our Clients Say"
          sectionTitleDown="Testimonials"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="https://prohealth-react.vercel.app/images/home_1/cta_img.png"
          title="Start Your Medical Journey Today!"
          subTitle="Book an appointment with one of our healthcare experts and receive world-class care in India."
        />
      </Section>
      {/* End Banner Section */}

      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Book an Appointment"
          sectionTitleUp="MEDICAL CONSULTATION"
          imgUrl="https://prohealth-html.vercel.app/assets/img/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Common Questions"
          sectionTitleUp="FREQUENTLY ASKED"
        />
      </Section>
      {/* End FAQ Section */}

      {/* End Brand Section */}
    </>
  );
}
