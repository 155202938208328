import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  CircularProgress,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const doctorsPerPage = 16;

  const location = useLocation();

  useEffect(() => {
    const fetchDoctors = async () => {
      const querySnapshot = await getDocs(collection(db, "doctors"));
      const doctorsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDoctors(doctorsData);
      setLoading(false);
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const department = params.get("department")?.trim().toLowerCase();
    if (department) {
      setFilterType("department");
      setFilterValue(department);
    }
  }, [location.search]);

  const filteredDoctors = doctors.filter((doctor) => {
    if (!filterType || !filterValue) return true;
    const doctorDepartment = doctor[filterType]?.trim().toLowerCase();
    return doctorDepartment && doctorDepartment.includes(filterValue);
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = filteredDoctors.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  pageTitle("Doctors");

  const formatDoctorName = (name) => {
    return name.startsWith("Dr.") ? name : `Dr. ${name}`;
  };

  const capitalizeEachWord = (string) => {
    if (!string) return "";
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="https://prohealth-html.vercel.app/assets/img/doctors/banner_img.png"
        title="Introduce You to <br />Our Experts"
        subTitle="The list of certified doctors with years of <br />professional experiences"
      />

      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          gutterBottom
          sx={{
            mb: 5,
            fontWeight: "bold",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Meet Our Doctors
        </Typography>

        <Container
          sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="filter-type-label">Filter By</InputLabel>
            <Select
              labelId="filter-type-label"
              value={filterType}
              label="Filter By"
              onChange={(e) => {
                setFilterType(e.target.value);
                setFilterValue("");
              }}
            >
              <MenuItem value="hospital">Hospital</MenuItem>
              <MenuItem value="department">Department</MenuItem>
              <MenuItem value="city">City</MenuItem>
            </Select>
          </FormControl>

          {filterType && (
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="filter-value-label">{filterType}</InputLabel>
              <Select
                labelId="filter-value-label"
                value={filterValue}
                label={filterType}
                onChange={(e) => setFilterValue(e.target.value)}
              >
                {[...new Set(doctors.map((doctor) => doctor[filterType]))]
                  .filter(Boolean)
                  .map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Container>

        <Grid container spacing={4} justifyContent="center">
          {currentDoctors.length > 0 ? (
            currentDoctors.map((doctor) => (
              <Grid item xs={12} sm={6} md={3} key={doctor.id}>
                <Link
                  to={`/doctor/${doctor.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      borderRadius: 2,
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                      padding: "1rem",
                      backgroundColor: "#fff",
                    }}
                  >
                    <CardMedia
                      component="div"
                      sx={{
                        height: "300px",
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "8px",
                        "&:hover img": {
                          transform: "scale(1.2)",
                          transition: "transform 0.5s ease-in-out",
                        },
                      }}
                    >
                      <img
                        src={doctor.image || "/images/doctors/default.png"}
                        alt={doctor.doctorName}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "transform 0.5s ease-in-out",
                        }}
                      />
                    </CardMedia>

                    <CardContent
                      sx={{
                        textAlign: "left", // Change to left alignment
                        flexGrow: 1,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          color: "#333",
                        }}
                      >
                        {formatDoctorName(doctor.doctorName)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontWeight: "400",
                          fontSize: "0.95rem",
                          color: "#666",
                          marginTop: "0.5rem",
                          fontStyle: "italic",
                        }}
                      >
                        {capitalizeEachWord(doctor.degree)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontWeight: "400",
                          fontSize: "0.95rem",
                          color: "#666",
                          marginTop: "0.5rem",
                          fontStyle: "italic",
                        }}
                      >
                        {capitalizeEachWord(doctor.currentPosition)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontWeight: "400",
                          fontSize: "0.9rem",
                          color: "#888",
                          marginTop: "0.25rem",
                        }}
                      >
                        {capitalizeEachWord(doctor.hospital)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontWeight: "800",
                          fontSize: "0.9rem",
                          color: "#888",
                          marginTop: "0.25rem",
                        }}
                      >
                        {capitalizeEachWord(doctor.department)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontWeight: "400",
                          fontSize: "0.9rem",
                          color: "#888",
                          marginTop: "0.25rem",
                        }}
                      >
                        Experience: {doctor.experience}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" sx={{ mt: 5 }}>
              No doctors found for the selected department.
            </Typography>
          )}
        </Grid>

        <Pagination
          count={Math.ceil(filteredDoctors.length / doctorsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ mt: 5, display: "flex", justifyContent: "center" }}
        />
      </Container>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="https://prohealth-html.vercel.app/assets/img/doctors/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
};

export default Doctors;
