import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HospitalPageTemplate from "../HospitalPageTemplate";
import { CircularProgress, Container } from "@mui/material";

const HospitalPage = () => {
  const { id } = useParams(); // Get the hospital name from the URL
  const [hospital, setHospital] = useState(null); // State to store the hospital data
  const [loading, setLoading] = useState(true);

  // Manually define all the necessary hospital data
  const manualHospitalData = {
    "jaypee-hospital": {
      name: "Jaypee Hospital",
      location: "Noida, India", // Use 'location' for hospital address
      description: `
      Jaypee Hospital is a flagship initiative from the Jaypee Group dedicated to providing quality healthcare. It is a tertiary care, multi-speciality hospital with 1200 beds capacity, operational in two phases.

      Highlights:
      - Holds NABH accreditation for quality healthcare standards.
      - Achieved the Achiever Emerging Multi-Speciality Hospital Award in 2018.
      - Ranked in the top 10 hospitals in North India by Times Health.
      - Successful in over 1350 organ transplants, with notable success in kidney and liver transplants.
      - Performed over 2000 knee surgeries and over 23,000 cancer procedures.
      `,
      image: `/images/hospitalImages/jaypee-hospital.jpg`,
      specialServices: `
      Jaypee Hospital offers a range of services designed for both local and international patients. 

      **Pre-arrival Services**:
      - Appointment scheduling.
      - Virtual OPD & Teleconsultation services.
      - Visa assistance for international patients.

      **Arrival and Stay Services**:
      - Airport pick-up and drop-off.
      - Customized diet plans, Wi-Fi, laundry, prayer rooms.
      - Assistance with FRRO registration and accommodation.

      **Post-Discharge Services**:
      - Teleconsultations and in-house translators.
      - Assistance in getting opinions and further treatments.
      `,
      teamAndSpecialties: `
      **Team**:
      - Over 400 highly skilled doctors from the best institutes around the world.
      - Specializes in cardiac surgery, neurology, orthopaedics, oncology, plastic surgery, and reconstructive surgery.

      **Centers of Excellence**:
      - Specialties in kidney donor surgery, cardiac surgery, and orthopaedics.
      `,
      infrastructure: `
      - Spread over a 25-acre campus with state-of-the-art technology.
      - 150 critical care unit beds, 24 ICU beds, 325 regular ward beds, 20 dialysis units.
      - Advanced equipment including MRI (3.0 Tesla), PET-CT, Da Vinci Robot for surgeries.
      `,
      facilities: `
      - Private rooms, family accommodation, personal assistance, Wi-Fi, and safe in the room.
      - Cafeteria, laundry, parking, prayer rooms, dedicated smoking areas.
      `,
      address: `
      Jaypee Hospital Road, Goberdhanpur, Sector 128, Noida, 201304, India.
      `,
      locationDetails: ` // Renamed to 'locationDetails' to avoid conflict
      **Nearby Transportation**:
      - 35 km from Indira Gandhi International Airport, Delhi.
      - 5 km from Sector 148 Metro Station, part of the Aqua Line, Delhi Metro.
      - 20 km from Hazrat Nizamuddin Railway Station, New Delhi.
      `,
    },
    "county-general": {
      name: "County General",
      location: "456 Elm St, Springfield",
      description:
        "County General has been serving the community for over 50 years with quality medical care.",
      image: "/images/hospitalImages/county-general.png",
    },
    // Add more manually defined hospital data here
  };

  useEffect(() => {
    // Find the hospital in the manually provided data
    const foundHospital = manualHospitalData[id];

    if (foundHospital) {
      setHospital(foundHospital);
    } else {
      console.error("No hospital data found for this name");
    }

    setLoading(false);

    // Disable the exhaustive-deps warning for manualHospitalData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Only id (name from URL) should be in the dependency array

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!hospital) {
    return <div>Hospital not found</div>; // Handle the case where the hospital is not found
  }

  return (
    <HospitalPageTemplate
      image={hospital.image}
      name={hospital.name}
      location={hospital.location}
      description={hospital.description}
      specialServices={hospital.specialServices}
      teamAndSpecialties={hospital.teamAndSpecialties}
      infrastructure={hospital.infrastructure}
      facilities={hospital.facilities}
      address={hospital.address}
      locationDetails={hospital.locationDetails} // Use the new key for nearby location details
    />
  );
};

export default HospitalPage;
